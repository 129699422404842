<template>
  <div class="bg-white h-full">
    <div class="p-4">
      <!-- <DepositForDoctors /> -->
      <DepositHistoryWS />
    </div>
  </div>
</template>

<script>
import { HODO_CREDIT_TYPE } from '@/utils/constants'
import DepositHistoryWS from './DepositHistoryWS.vue'

export default {
  name: 'DepositPointsWS',
  components: { DepositHistoryWS },
  data () {
    return {
      HODO_CREDIT_TYPE
    }
  }
}
</script>

<style lang="scss" scoped>
</style>