<template>
  <div class="cs-container">
    <div class="flex items-center justify-between gap-2">
      <h2 class="fs-20 font-bold uppercase mb-0">
        {{ $t("Lịch sử Nạp điểm") }}
      </h2>
      <!-- <el-tooltip
        class="item"
        effect="dark"
        content="Thiết lập tỷ lệ nạp điểm"
        placement="top-start"
      >
        <svg
          @click="handleShowModalConfig"
          class="cursor-pointer"
          width="28"
          height="28"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#20409B"
            d="M19.9 12.66a1 1 0 0 1 0-1.32l1.28-1.44a1 1 0 0 0 .12-1.17l-2-3.46a1 1 0 0 0-1.07-.48l-1.88.38a1 1 0 0 1-1.15-.66l-.61-1.83a1 1 0 0 0-.95-.68h-4a1 1 0 0 0-1 .68l-.56 1.83a1 1 0 0 1-1.15.66L5 4.79a1 1 0 0 0-1 .48L2 8.73a1 1 0 0 0 .1 1.17l1.27 1.44a1 1 0 0 1 0 1.32L2.1 14.1a1 1 0 0 0-.1 1.17l2 3.46a1 1 0 0 0 1.07.48l1.88-.38a1 1 0 0 1 1.15.66l.61 1.83a1 1 0 0 0 1 .68h4a1 1 0 0 0 .95-.68l.61-1.83a1 1 0 0 1 1.15-.66l1.88.38a1 1 0 0 0 1.07-.48l2-3.46a1 1 0 0 0-.12-1.17ZM18.41 14l.8.9l-1.28 2.22l-1.18-.24a3 3 0 0 0-3.45 2L12.92 20h-2.56L10 18.86a3 3 0 0 0-3.45-2l-1.18.24l-1.3-2.21l.8-.9a3 3 0 0 0 0-4l-.8-.9l1.28-2.2l1.18.24a3 3 0 0 0 3.45-2L10.36 4h2.56l.38 1.14a3 3 0 0 0 3.45 2l1.18-.24l1.28 2.22l-.8.9a3 3 0 0 0 0 3.98m-6.77-6a4 4 0 1 0 4 4a4 4 0 0 0-4-4m0 6a2 2 0 1 1 2-2a2 2 0 0 1-2 2"
          />
        </svg>
      </el-tooltip>-->
    </div>
    <div class="flex flex-col md:flex-row gap-2 mt-4">
      <div class="flex flex-col gap-1 flex-1">
        <label>{{ $t("Tìm kiếm") }}</label>
        <el-input
          @input="handleDebounce"
          clearable
          placeholder="Nhập tên người nạp"
          v-model="searchValue"
        ></el-input>
      </div>
      <div class="flex flex-col gap-1" style="min-width: 300px">
        <label>{{ $t("Từ ngày") }}</label>
        <el-date-picker
          id="startDate"
          v-model="startDate"
          type="date"
          placeholder="DD/MM/YYYY"
          @input="handleDebounce"
        ></el-date-picker>
      </div>
      <div class="flex flex-col gap-1" style="min-width: 300px">
        <label>{{ $t("Đến ngày") }}</label>
        <el-date-picker
          id="endDate"
          v-model="endDate"
          type="date"
          placeholder="DD/MM/YYYY"
          @input="handleDebounce"
        ></el-date-picker>
      </div>
    </div>
    <div class="flex justify-end my-2">
      <el-button
        @click="handleShowModalSelectDoctors"
        size="small"
        type="primary"
        class="text-white"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M28 16C28 16.2652 27.8946 16.5196 27.7071 16.7071C27.5196 16.8946 27.2652 17 27 17H17V27C17 27.2652 16.8946 27.5196 16.7071 27.7071C16.5196 27.8946 16.2652 28 16 28C15.7348 28 15.4804 27.8946 15.2929 27.7071C15.1054 27.5196 15 27.2652 15 27V17H5C4.73478 17 4.48043 16.8946 4.29289 16.7071C4.10536 16.5196 4 16.2652 4 16C4 15.7348 4.10536 15.4804 4.29289 15.2929C4.48043 15.1054 4.73478 15 5 15H15V5C15 4.73478 15.1054 4.48043 15.2929 4.29289C15.4804 4.10536 15.7348 4 16 4C16.2652 4 16.5196 4.10536 16.7071 4.29289C16.8946 4.48043 17 4.73478 17 5V15H27C27.2652 15 27.5196 15.1054 27.7071 15.2929C27.8946 15.4804 28 15.7348 28 16Z"
            fill="white"
          />
        </svg>
        {{ $t("Nạp điểm") }}
      </el-button>
    </div>

    <el-table
      @row-click="handleViewDepositDetail"
      :data="deposits"
      border
      style="width: 100%"
    >
      <el-table-column
        prop="name"
        :formatter="getDateTime"
        :label="$t('Thời gian nạp')"
      ></el-table-column>
      <el-table-column
        prop="create_by_user.name"
        :label="$t(' Người nạp')"
      ></el-table-column>
      <el-table-column
        class-name="text-center"
        prop="meta_data.total_doctor"
        :label="$t('Tổng người được nạp')"
      ></el-table-column>
      <el-table-column
        class-name="text-center"
        prop="equivalent_point"
        :formatter="
          (row) =>
            numberFormat(row && row.meta_data && row.meta_data.total_point)
        "
        :label="$t('Tổng điểm nạp')"
      ></el-table-column>
      <el-table-column
        class-name="text-right"
        prop="bill_value"
        :formatter="
          (row) =>
            numberFormat(
              row && row.meta_data && row.meta_data.total_money,
              'VND'
            )
        "
        :label="$t('Tổng tiền nạp')"
      ></el-table-column>
    </el-table>
    <AppPagination
      :paginationProps="pagination"
      @onChangePagination="handleGetDepositHistory"
    />
  </div>
</template>

<script>
import { debounce } from 'lodash'
import appUtils from '../../../utils/appUtils'
import { AppPagination } from '../../Common'
import { DEPOSIT_SCOPE } from '../../../utils/constants'

export default {
  name: 'DepositHistory',
  components: {
    AppPagination
  },
  data () {
    return {
      searchValue: '',
      startDate: null,
      endDate: null,
      deposits: [],

      pagination: {
        currentPage: 1,
        pageSize: 10,
        totalItems: 0
      }
    }
  },
  created () {
    this.handleGetDepositHistory()
  },
  methods: {
    handleShowModalConfig () {
      this.$refs.ModalConfigDeposit.handleOpenModal()
    },
    handleShowModalSelectDoctors () {
      this.$router.push({ name: 'DepositForDoctorsWS' })
      // this.$refs.ModalSelectDoctors.handleOpen()
    },
    async handleGetDepositHistory (data) {
      if (!this.$globalClinic?.id) return

      try {
        const params = {
          org_id: this.$globalClinic?.org_id,
          type: 5,
          order: 'desc',
          sort_by: 'created_at',
          page_num: data?.currentPage || this.pagination.currentPage,
          page_size: data?.pageSize || this.pagination.pageSize,
          keyword: this.searchValue,
          from_date: this.startDate
            ? window.moment(this.startDate).startOf('days').valueOf()
            : undefined,
          to_date: this.endDate
            ? window.moment(this.endDate).endOf('days').valueOf()
            : undefined,
          scope: DEPOSIT_SCOPE.ws,
          scope_id: this.$globalClinic?.id
        }

        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getDepositHistory(params)
        this.deposits = response.data?.data || []
        this.pagination = {
          currentPage: response.data?.page?.page_num || 1,
          pageSize: response.data?.page?.page_size || 10,
          totalItems: response.data?.page?.total || 0
        }
      } catch (error) {
        console.log(error)
      }
    },
    getDateTime (row) {
      if (!row?.created_at) return
      return window.moment(row?.created_at).format('HH:mm DD/MM/YYYY')
    },
    numberFormat (value, append) {
      if (!value) return ''

      return appUtils.numberFormat(value, append)
    },
    handleDebounce: debounce(function () {
      this.handleGetDepositHistory({ currentPage: 1 })
    }, 500),
    handleViewDepositDetail (row) {
      this.$router
        .push({
          name: 'DepositDetailWS',
          params: {
            id: row.id
          }
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.cs-container * {
  color: black;
}

::v-deep {
  thead * {
    color: black !important;
  }
  .el-input__inner {
    border: 1px solid #dcdfe6 !important;
    background-color: white !important;
  }
}
</style>