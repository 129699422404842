import { render, staticRenderFns } from "./DepositPointsWS.vue?vue&type=template&id=7364a224&scoped=true"
import script from "./DepositPointsWS.vue?vue&type=script&lang=js"
export * from "./DepositPointsWS.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7364a224",
  null
  
)

export default component.exports